export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  props: {
    item: {
      type: Object,
      default: {}
    },
    id: {
      type: String,
      default: ''
    }
  }
}
